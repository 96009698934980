.main {
  margin-right: auto;
  margin-left: auto;
  .container {
    padding: 8px 64px;
    display: flex;
    flex-direction: column;
    .hero {
      display: flex;
      gap: 88px;
      .heroTitle {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .title {
          color: var(--Black, #050608);
          font-family: Hind;
          font-size: 56px;
          font-style: normal;
          font-weight: 300;
          line-height: 60px; /* 107.143% */
          letter-spacing: 5.6px;
          text-transform: uppercase;
        }
        .description {
          color: #6c6c6c;
          font-family: Hind;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px; /* 155.556% */
          letter-spacing: -0.36px;
        }
      }
      .img {
        max-height: 535px;
        img {
          height: 535px;
          aspect-ratio: auto;
          width: auto;
        }
      }
    }
    .line {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 48px 0;
    }
    .join {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        color: var(--Black, #050608);
        text-align: center;
        font-family: Hind;
        font-size: 56px;
        font-style: normal;
        font-weight: 300;
        line-height: 60px; /* 107.143% */
        letter-spacing: 5.6px;
        text-transform: uppercase;
      }
      .images {
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .content {
          position: relative;
          height: 150px;
          border-radius: 16px;
          overflow: hidden; 
        }

        .contenttwo {
          position: relative;
          height: 150px;
          border-radius: 16px;
          overflow: hidden; 
        }
        
        .content::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url("../assets/images/image_123650291\ \(1\)\ 1.svg");
          background-size: cover;
          filter: blur(0); 
          transition: filter 0.3s; 
        }
        
        .content:hover::before {
          filter: blur(4px); 
        }
         
        .content:hover .texts .describe {
          visibility: visible; 
        }
        
        .texts .describe {
          visibility: hidden; 
        }
        .texts .title {
          visibility: hidden; 
          // margin-bottom: -100px;
        }
        
        .content:hover .texts .title,
        .content:hover .texts .describe {
          visibility: visible; 
          // margin-top: 100px;
        }

        .content:hover {
          height: 350px;
          background-size: contain;
          background-position-x: 0;
          background-repeat: no-repeat;
          // border-radius: 18px;
          animation-delay: 3s;
        }
          .texts {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 4px;
            
            .title {
              color: var(--White, #fff);
              font-family: Hind;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px; /* 100% */
              letter-spacing: -0.48px;
            }
            .describe {
              color: #6c6c6c;
              font-family: Hind;
              font-size: 14px;
              color: #fff;
              font-style: normal;
              font-weight: 300;
              line-height: 18px; /* 128.571% */
            }
          }
        }

        .contenttwo {
          position: relative;
          height: 150px;
          border-radius: 16px;
          overflow: hidden; 
        }
        
        .contenttwo::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url("../assets/images/Rectangle\ 18.png");
          background-size: cover;
          filter: blur(0); 
          transition: filter 0.3s; 
        }
        
        .contenttwo:hover::before {
          filter: blur(4px); 
        }
         
        .contenttwo:hover .texts .describetwo {
          visibility: visible; 
        }
        
        .textstwo .describetwo {
          visibility: hidden; 
        }
        .textstwo .titletwo {
          visibility: hidden; 
          // margin-bottom: -100px;
        }
        
        .contenttwo:hover .textstwo .titletwo,
        .contenttwo:hover .textstwo .describetwo {
          visibility: visible; 
          // margin-top: 100px;
        }

        .contenttwo:hover {
          background-image: url("../assets/images/Rectangle\ 18.png");
          height: 350px;
          background-size: cover;
          background-position-x: center;
          background-repeat: no-repeat;
          // border-radius: 18px;
          animation-delay: 3s;
        }
          .textstwo {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 4px;
            
            .titletwo {
              color: var(--White, #fff);
              font-family: Hind;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px; /* 100% */
              letter-spacing: -0.48px;
            }
            .describetwo {
              color: #6c6c6c;
              font-family: Hind;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 18px; /* 128.571% */
            }
          }
        

      //   .imageTwo{
      //     background-image: url("../assets/images/Rectangle\ 18680.svg");
      //     background-size: cover;
      //     height: 150px;
      //     border-radius: 16px;
      //     background-position: center;
      // }
      // .imageTwo:hover {
      //   height: 350px;
      //   background-size: cover;
      //   border-radius: 18px;
      //   background-image: url("../assets/images/projecttwo.png");
      // }

      
        .soon {
          width: 533px;
          height: 150px;
          border-radius: 16px;
          background: #d9d9d9;
          color: var(--Black, #050608);
          font-family: SemplicitaPro;
          font-size: 56px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 84px */
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 48px;
      margin-bottom: 48px;
      .title {
        color: var(--Black, #050608);
        font-family: Hind;
        font-size: 56px;
        font-style: normal;
        font-weight: 300;
        line-height: 60px; /* 107.143% */
        letter-spacing: 5.6px;
        text-transform: uppercase;
      }
      .actionButtons {
        display: flex;
        gap: 24px;
        button {
          display: flex;
          padding: 16px 32px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 72px;
          border: 1px solid var(--Black, #050608);
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }

@media (min-width: 1400px) {
  .main {
    max-width: 1440px;
    
  }
}

@media (max-width: 768px) {
  /* Existing styles */
  .main {
    .container {
      .join {
        .images {
          .content {
            height: 200px;
            &:hover {
              height: 210px;
              background-size: cover;
              animation-delay: 3s;
              background-position-x: -75px;
              background-repeat: no-repeat;
            }
          }
        }
        .contenttwo:hover {
          background-image: url("../assets/images/Rectangle\ 18.png");
          height: 200px; 
          background-size: cover;
          animation-delay: 3s;
          background-position: center;
        }
      }
    }
  }

  .main .container .hero .img {
    max-height: unset;
    height: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}



@media (max-width: 1024px){
  .main {
    .container{
      .img {
          max-width: 330px;
        img {
          width: 100%;
        }
      }
      .join{
        .images{
          .content{
            height: 200px;
            &:hover{
              height: 220px;
              background-size: cover;
              animation-delay: 3s;
              background-position-x: -75px;
              background-repeat: no-repeat;
            }
          }
        }
        .contenttwo:hover {
          background-image: url("../assets/images/Rectangle\ 18.png");
          height: 220px; 
          background-size: contain;
          animation-delay: 3s;
          background-position: -500px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .main {
    max-width: 100vw;
    .container {
      padding: 8px 16px !important;
      .hero {
        flex-direction: column;
        align-items: center;
        .heroTitle {
          .title {
            font-size: 32px;
          }
        }
        .img {
          
          img {
            width: 100%;
          }
        }
      }
      .join {
        .title {
          font-size: 38px;
        }
        img {
          width: 100%;
        }
        .soon {
          width: 100% !important;
        }
      }
    }
  }
}

@keyframes slide-up {
  from {
    margin-top: 100%;
  }

  to {
    margin-top: 0%;
  }
}
