.main {
  margin-right: auto;
  margin-left: auto;

  .container {
    padding: 8px 64px;
    display: flex;
    flex-direction: column;
    background: black;
    .hero {
      display: flex;
      gap: 88px;
      justify-content: flex-start;
      align-items: flex-start;
      .heroTitle {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .title {
          color: var(--Cream, #f0ece9);
          font-family: SemplicitaPro;
          font-size: 56px;
          font-style: normal;
          font-weight: 500;
          line-height: 60px; /* 107.143% */
          letter-spacing: -1.12px;
          text-transform: capitalize;
        }
        .description {
          color: #cecece;
          font-family: SemplicitaPro;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
          letter-spacing: -0.36px;
        }
      }
      .img {
        max-height: 535px;
        img {
          height: 535px;
          aspect-ratio: auto;
          width: auto;
        }
      }
      button {
        border-radius: 72px;
        border: 1px solid var(--Cream, #f0ece9);
        background: rgba(255, 255, 255, 0.05);
        display: flex;
        padding: 16px 32px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--Cream, #f0ece9);
        font-family: SemplicitaPro;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
      }
    }
    .line {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 48px 0;
    }
    .hydraVerse {
      display: flex;
      justify-content: space-between;
      gap: 204px;
      width: 100%;
      .images {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .content {
          position: relative;
          .texts {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 4px;
            .title {
              color: var(--White, #fff);
              font-family: Hind;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px; /* 100% */
              letter-spacing: -0.48px;
            }
            .describe {
              color: #6c6c6c;
              font-family: Hind;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 18px; /* 128.571% */
            }
          }
        }
        .soon {
          width: 533px;
          height: 150px;
          border-radius: 16px;
          background: #d9d9d9;
          color: var(--Black, #050608);
          font-family: SemplicitaPro;
          font-size: 56px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 84px */
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .titleHydra {
        color: var(--Cream, #f0ece9);
        text-align: right;
        font-family: SemplicitaPro;
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 64px; /* 100% */
        letter-spacing: -2.56px;
        text-transform: capitalize;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 48px;
      margin-bottom: 48px;
      .title {
        color: var(--Cream, #f0ece9);
        text-align: center;
        font-family: SemplicitaPro;
        font-size: 64px;
        font-style: normal;
        font-weight: 500;
        line-height: 64px; /* 100% */
        letter-spacing: -2.56px;
        text-transform: capitalize;
      }
      .actionButtons {
        display: flex;
        gap: 24px;
        button {
          display: flex;
          padding: 16px 32px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: var(--Cream, #f0ece9);
          font-family: SemplicitaPro;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
          border-radius: 72px;
          border: 1px solid var(--Cream, #f0ece9);
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .main {
    max-width: 1440px;
  }
}
@media (max-width: 1280px) {
  .main {
    max-width: 100vw;
    .container {
      padding: 8px 16px !important;
      .hero {
        flex-direction: column;
        align-items: center;
        .heroTitle {
          align-items: center;
          .title {
            font-size: 32px;
          }
        }
        .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .hydraVerse {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 48px;
        gap: 104px;
        .titleHydra {
          font-size: 32px;
        }
        img {
          width: 100%;
        }
        .soon {
          width: 100% !important;
        }
      }
    }
  }
}
