.navbar {
  background: white;
  position: relative;
  width: 100%;
  padding: 8px 64px;
  .logo {
    width: 63px;
    height: auto;
    aspect-ratio: auto;
  }
}
@media (min-width: 1400px) {
  .navbar {
    max-width: 1440px;
  }
}

@media (max-width: 1280px) {
  .navbar {
    padding: 8px 16px;
    width: auto !important;
  }
}
