.footer {
  display: flex;
  flex-direction: column;
  padding: 8px 64px;
  justify-content: center;
  align-items: center;
  .logoLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    img {
      width: 43px;
      height: auto;
      aspect-ratio: auto;
    }

    .menu {
      display: flex;
      gap: 32px;
      span {
        color: #000;
        font-family: SemplicitaPro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        cursor: pointer;
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    color: black;
    background: black;
    margin: 24px 0;
  }
  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    span {
      color: var(--Black, #050608);
      font-family: Hind;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%; /* 21px */

      cursor: pointer;
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}

@media (min-width: 1400px) {
  .footer {
    max-width: 1440px;
  }
}
@media (max-width: 1280px) {
  .footer {
    padding: 8px 16px !important;
  }
}
